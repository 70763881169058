import {Checkbox, TableCell, TableHead, TableRow, TableSortLabel} from "@material-ui/core";
import React from "react";
import {PhotoDoc} from "../../../../../lib/shared/domain/PhotoDoc";
import {Order, usePhotoTableStyles} from "./PhotosTable";

interface HeadCell {
    disablePadding: boolean;
    id: keyof PhotoDoc;
    label: string;
    numeric: boolean;
    date: boolean;
}

const headCells: HeadCell[] = [
    {id: 'path', numeric: false, date: false, disablePadding: false, label: 'Path'},
    {id: 'title', numeric: false, date: false, disablePadding: false, label: 'Photo Title'},
    {id: 'client_modified', numeric: false, date: true, disablePadding: false, label: 'Modified'},
    {id: 'visible', numeric: false, date: false, disablePadding: false, label: 'Visible'},
];

interface PhotosTableHeadProps {
    classes: ReturnType<typeof usePhotoTableStyles>;
    numSelected: number;
    onRequestSort: (property: keyof PhotoDoc) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

export function PhotosTableHead(props: PhotosTableHeadProps) {
    const {classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props;
    const createSortHandler = (property: keyof PhotoDoc) => () => {
        onRequestSort(property);
    };
    
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{'aria-label': 'select all desserts'}}
                    />
                </TableCell>
                <TableCell/>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell align={"center"}>
                    Tags
                </TableCell>
                <TableCell/>
            </TableRow>
        </TableHead>
    );
}
