// @ts-ignore
const baseUrl = process.env.REACT_APP_API_BASE_URL;


export async function callGetApi<T>(endpoint: string): Promise<T> {
    const response = await fetch(baseUrl + endpoint);
    if (response.status === 200) {
        console.log(`Received 200 for resource ${endpoint}`);
        return await response.json();
    }
    const body = await response.text();
    throw new Error(`ERROR GET ${endpoint} (${response.status}): ${body}`);
}

export async function callPostApi<TRequest, TResponse>(endpoint: string, body: TRequest): Promise<TResponse> {
    const response = await fetch(baseUrl + endpoint, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    });
    if (response.status >= 200 && response.status < 300) {
        console.log(`Received ${response.status} for resource ${endpoint}`);
        return await response.json();
    }
    const resbody = await response.text();
    throw new Error(`ERROR POST ${endpoint} (${response.status}): ${resbody}`);
}

export function tryParseIntOrOriginalValue(value: string): number | string {
    try {
        let num = parseInt(value);
        if (num) {
            return num;
        }
    } catch (e) {
    }
    return value;
}

export function getReadableFileSizeString(fileSizeInBytes: number): string {
    let i = -1;
    const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);
    
    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};
