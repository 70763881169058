import React from "react";
import {Navigate} from "react-router-dom";
import PrivateView from "./misc/PrivateView";
import AdminLayout from "./sections/admin/AdminLayout";
import DashboardView from "./sections/admin/DashboardView";
import PhotosView from "./sections/admin/PhotosView";
import PhotoEdit from "./sections/admin/PhotosView/PhotoEdit";
import SettingsView from "./sections/admin/SettingsView";
import AuthLayout from "./sections/auth/AuthLayout";
import DropboxCallbackView from "./sections/auth/DropboxCallbackView";
import LoginView from "./sections/auth/LoginView";
import NotFoundView from "./sections/public/NotFoundView";
import PhotoGalleryView from "./sections/public/PhotoGalleryView";
import PublicLayout from "./sections/public/PublicLayout";

const routes = [
    {
        path: 'admin',
        element: <PrivateView><AdminLayout/></PrivateView>,
        children: [
            {path: 'dashboard', element: <DashboardView/>},
            {path: 'photos', element: <PhotosView/>},
            {path: 'photos/:photoId/edit', element: <PhotoEdit/>},
            {path: 'settings', element: <SettingsView/>},
            {path: '*', element: <Navigate to="dashboard"/>}
        ]
    },
    {
        path: 'auth',
        element: <AuthLayout/>,
        children: [
            {path: 'login', element: <LoginView/>},
            {path: 'dropbox-callback', element: <DropboxCallbackView/>},
        ]
    },
    {
        path: '/',
        element: <PublicLayout/>,
        children: [
            {path: '', element: <PhotoGalleryView/>},
            {path: '404', element: <NotFoundView/>},
            {path: '*', element: <Navigate to="/404"/>}
        ]
    }
];

export default routes;
