import {Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import React, {useState} from "react";
import {ImageLabels} from "../../../../../lib/shared/domain/PhotoDoc";

const useStyles = makeStyles((theme) => ({
    suggestedTag: {
        margin: theme.spacing(1),
        // backgroundColor: theme.palette.primary.dark,
        // color: theme.palette.primary.contrastText,
        textTransform: "none"
    },
    checkbox: {
        padding: 0,
        backgroundColor: "transparent"
    }
}));

export function SuggestedTag({tag}: { tag: ImageLabels }) {
    const [checked, setChecked] = useState(tag.applied || false);
    const classes = useStyles();
    
    function handleToggle() {
        tag.applied = !tag.applied;
        setChecked(tag.applied);
    }
    
    return (
        <Button
            variant="contained"
            disableRipple={true}
            color="primary"
            className={classes.suggestedTag}
            onClick={handleToggle}
            size="small"
            startIcon={checked ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
        >
            {tag.description}
        </Button>
    );
}
