import {Box, Divider, Drawer, Hidden, List, makeStyles, Typography} from '@material-ui/core';
import firebase from "firebase";
import React, {FC, useEffect, useState} from 'react';
import {BarChart as BarChartIcon, Camera as CameraIcon, Settings as SettingsIcon} from 'react-feather';
import {useLocation} from 'react-router-dom';
import {useUserStatus} from "../../../../contexts/Firebase";
import {UserDoc} from "../../../../domain/UserDoc";
import LoadingAvatarImage from "../../LoadingAvatarImage";
import NavItem from "./NavItem";

const items = {
    "dashboard": {
        href: '/admin/dashboard',
        icon: BarChartIcon,
        title: 'Dashboard'
    },
    "photos": {
        href: '/admin/photos',
        icon: CameraIcon,
        title: 'Photos'
    },
    "settings": {
        href: '/admin/settings',
        icon: SettingsIcon,
        title: 'Settings'
    },
};

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
}));

const NavBar: FC<NavBarProps> = ({onMobileClose, openMobile}) => {
    const classes = useStyles();
    const location = useLocation();
    const userStatus = useUserStatus();
    const [user, setUser] = useState<firebase.UserInfo | undefined>(undefined);
    const [account, setAccount] = useState<UserDoc | undefined>(undefined);
    
    useEffect(() => {
        setUser(userStatus.user);
        setAccount(userStatus.account);
    }, [userStatus]);
    
    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);
    
    
    const content = (
        <
            Box
            height="100%"
            display="flex"
            flexDirection="column"
        >
            < Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                p={2}
            >
                <LoadingAvatarImage href={items.settings.href} src={user?.photoURL}/>
                <Typography
                    color="textPrimary"
                    variant="h5"
                >
                    {account?.displayName ? account.displayName : ""}
                </Typography>
            </Box>
            <Divider/>
            <Box p={2}>
                <List>
                    {Object.values(items).map((item) => (
                        <NavItem
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                        />
                    ))}
                </List>
            </Box>
        </Box>
    );
    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{paper: classes.mobileDrawer}}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{paper: classes.desktopDrawer}}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

type NavBarProps = {
    onMobileClose?: () => void,
    openMobile?: boolean;
};

export default NavBar;
