import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import React from 'react';
import ReactDOM from 'react-dom';
import * as ReactGA from "react-ga";
import {BrowserRouter} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './sections/App';

console.log('node env:', process.env.NODE_ENV);
console.log('app env:', process.env.REACT_APP_ENV);
console.log('ga:', process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);

if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID !== undefined) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
}

ReactDOM.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
