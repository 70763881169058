import {AppBar, Badge, Box, Hidden, IconButton, makeStyles, Toolbar, Typography} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import clsx from 'clsx';
import React, {FC, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {useFirebase} from "../../../contexts/Firebase";

const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        width: 60,
        height: 60
    },
    logo: {
        color: theme.palette.getContrastText(theme.palette.primary.dark)
    }
}));

const TopBar: FC<TopBarProps> = ({
                                     className,
                                     onMobileNavOpen,
                                     ...rest
                                 }) => {
    const classes = useStyles();
    const firebase = useFirebase();
    const navigate = useNavigate();
    const [notifications] = useState([]);
    
    const doSignOut = async () => {
        await firebase.auth().signOut();
        navigate("/");
    };
    
    return (
        <AppBar
            className={clsx(classes.root, className)}
            elevation={0}
            {...rest}
        >
            <Toolbar>
                <Hidden lgUp>
                    <IconButton
                        color="inherit"
                        onClick={onMobileNavOpen}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Hidden>
                <RouterLink to="/">
                    <Typography className={classes.logo}
                                variant="h2">BobisPix</Typography>
                </RouterLink>
                <Box flexGrow={1}/>
                <Hidden mdDown>
                    <IconButton color="inherit">
                        <Badge
                            badgeContent={notifications.length}
                            color="primary"
                            variant="dot"
                        >
                            <NotificationsIcon/>
                        </Badge>
                    </IconButton>
                    <IconButton onClick={doSignOut} color="inherit">
                        <ExitToAppIcon/>
                    </IconButton>
                </Hidden>
    
            </Toolbar>
        </AppBar>
    );
};

type TopBarProps = {
    className?: string;
    onMobileNavOpen?: () => void;
};

export default TopBar;
