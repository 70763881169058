import {Button, Container, Grid, TextField, Typography} from "@material-ui/core";
import {createStyles, makeStyles} from '@material-ui/core/styles';
import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {useFirebase, useUserStatus} from "../../contexts/Firebase";
import {UserDoc} from "../../domain/UserDoc";
import Page from "../../misc/Page";
import LoadingAvatarImage from "./LoadingAvatarImage";

const useStyles = makeStyles(() =>
                                 createStyles({
                                                  root: {},
                                                  textField: {},
                                                  hiddenInput: {
                                                      display: 'none',
                                                  },
                                              }),
);

const SettingsView: FC = () => {
    const firebase = useFirebase();
    const {account} = useUserStatus();
    const [newAccount, setNewAccount] = useState(Object.assign({}, account));
    const [newAvatar, setNewAvatar] = useState(newAccount?.photoURL);
    const [avatarFile, setAvatarFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    
    useEffect(() => {
        if (avatarFile) {
            setNewAvatar(URL.createObjectURL(avatarFile));
        }
    }, [avatarFile])
    
    const getAccountOnChange = (key: keyof UserDoc) => {
        return (event: ChangeEvent<HTMLInputElement>) => {
            if (newAccount) {
                // @ts-ignore
                newAccount[key] = event.target.value;
                setNewAccount({...newAccount});
            }
        }
    }
    
    const handleAvatarChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setAvatarFile(event.target.files[0]);
        } else {
            setAvatarFile(null);
        }
    }
    
    const removeAvatar = () => {
        setAvatarFile(null);
    }
    
    const handleSave = async () => {
        setLoading(true);
        if (newAccount) {
            if (avatarFile) {
                // upload avatar
                const storageRef = firebase.storage().ref();
                const avatarRef = storageRef.child(`${newAccount.id}/assets/avatar`);
                const data = await avatarFile.arrayBuffer()
                await avatarRef.put(data);
                newAccount.photoURL = avatarRef.fullPath;
            }
            Object.assign(account, newAccount);
            await firebase.firestore().doc(`/users/${newAccount.id}`).set(newAccount);
        }
        setLoading(false);
    }
    
    console.log(account, newAccount);
    return (
        <Page title={"Settings"}>
            <Container maxWidth={"lg"}>
                <Grid container className={classes.root} spacing={3}>
                    <Grid item className={classes.textField} xs={12} sm={4}>
                        <TextField
                            id="first-name"
                            label="First Name"
                            style={{margin: 8}}
                            placeholder="First Name"
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            value={newAccount.firstName}
                            onChange={getAccountOnChange("firstName")}
                        />
                    </Grid>
                    <Grid item className={classes.textField} xs={12} sm={4}>
                        <TextField
                            id="last-name"
                            label="Last Name"
                            style={{margin: 8}}
                            placeholder="Last Name"
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            value={newAccount.lastName}
                            onChange={getAccountOnChange("lastName")}
                        />
                    </Grid>
                    <Grid item className={classes.textField} xs={12} sm={4}>
                        <TextField
                            id="display-name"
                            label="Display Name"
                            style={{margin: 8}}
                            placeholder="Display Name"
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            value={newAccount.displayName}
                            onChange={getAccountOnChange("displayName")}
                        />
                    </Grid>
                    <Grid item className={classes.textField} xs={12} sm={4}>
                        <TextField
                            id="email"
                            label="Email"
                            style={{margin: 8}}
                            placeholder="Email"
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            value={newAccount.email}
                            onChange={getAccountOnChange("email")}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <Typography>Avatar:</Typography>
                        </div>
                        <div>
                            <LoadingAvatarImage src={newAvatar}/>
                        </div>
                        <div>
                            <input
                                accept="image/*"
                                className={classes.hiddenInput}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={handleAvatarChange}
                            />
                            <label htmlFor="contained-button-file">
                                <Button variant="contained" color="primary" component="span">
                                    Upload
                                </Button>
                            </label>
                            <Button variant="contained" color="default" component="span" onClick={removeAvatar}>
                                Remove
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                    
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={1}>
                            <Button onClick={handleSave} disabled={loading}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};

export default SettingsView;
