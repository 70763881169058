import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import React, {createContext, FC, useContext, useEffect, useState} from "react";
import {BehaviorSubject} from "rxjs";
import {UserDoc} from "../domain/UserDoc";

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = firebase.initializeApp(config);

if (process.env.REACT_APP_ENV === "production") {
    app.analytics();
}
if (process.env.REACT_APP_EMULATOR_FIRESTORE) {
    const [host, port_str] = process.env.REACT_APP_EMULATOR_FIRESTORE.split(":");
    const port = parseInt(port_str);
    console.log(`Using Firebase Emulator at ${host} port ${port}`);
    app.firestore().useEmulator(host, port);
}
if (process.env.REACT_APP_EMULATOR_AUTH) {
    console.log(`Using Auth Emulator at ${process.env.REACT_APP_EMULATOR_AUTH}`);
    app.auth().useEmulator(process.env.REACT_APP_EMULATOR_AUTH);
}

export class LoggedInStatus {
    static UNKNOWN = "unknown";
    static LOGGED_IN = "logged-in";
    static LOGGED_OUT = "logged-out";
}

const defaultUserState: UserSubjectType = {
    user: undefined,
    status: LoggedInStatus.UNKNOWN,
    account: undefined
};
type UserSubjectType = {
    status: LoggedInStatus;
    user: firebase.UserInfo | undefined;
    account: UserDoc | undefined;
}
const currentUserSubject = new BehaviorSubject<UserSubjectType>(defaultUserState);

app.auth().onAuthStateChanged(async authUser => {
    let status: LoggedInStatus, user: firebase.UserInfo | undefined, account: UserDoc | undefined;
    if (authUser) {
        user = authUser.toJSON() as firebase.UserInfo;
        status = LoggedInStatus.LOGGED_IN;
        const userDocRef = firebase.firestore().doc(`/users/${user?.uid}`);
        userDocRef.onSnapshot((changedUserDoc) => onUserDocChange(changedUserDoc.data() as UserDoc));
        const userDoc = await userDocRef.get();
        account = userDoc.data() as UserDoc;
        account.id = userDoc.id;
    } else {
        status = LoggedInStatus.LOGGED_OUT;
    }
    const msg = {user, status, account};
    currentUserSubject.next(msg);
});

const onUserDocChange = (userDoc: UserDoc | undefined) => {
    const current = currentUserSubject.getValue();
    const account: UserDoc = {...userDoc, ...current.account} as UserDoc;
    console.log("snapshot", account);
    currentUserSubject.next({
                                account,
                                status: current.status,
                                user: current.user
                            });
}

export const FirebaseContext = createContext(app);
export const UserContext = createContext(defaultUserState);
export const useUserStatus = () => useContext(UserContext);
export const useFirebase = () => useContext(FirebaseContext);

export const FirebaseProvider: FC = ({children}) => {
    const [userStatus, setUserStatus] = useState(defaultUserState);
    useEffect(() => {
        const subscription = currentUserSubject.subscribe((userState) => {
            // console.log("UserContext.Provider state", userState);
            setUserStatus(userState);
        });
        return subscription.unsubscribe;
    }, []);
    
    return (
        <FirebaseContext.Provider value={app}>
            <UserContext.Provider value={userStatus}>
                {children}
            </UserContext.Provider>
        </FirebaseContext.Provider>
    );
}

