import {Table, TableBody, TableCell, TableContainer, TablePagination, TableRow} from "@material-ui/core";
import {createStyles, makeStyles} from '@material-ui/core/styles';
import React from 'react';
import {PhotoDoc} from "../../../../../lib/shared/domain/PhotoDoc";
import {Doc} from "../../../misc/Doc";
import {PhotosTableHead} from "./PhotosTableHead";
import {PhotosTableToolbar} from "./PhotosTableToolbar";
import PhotoTableRow from "./PhotoTableRow";


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export type Order = 'asc' | 'desc';

function getComparator(
    order: Order,
    orderBy: keyof PhotoDoc,
): (a: PhotoDoc, b: PhotoDoc) => number {
    return order === 'desc'
           ? (a, b) => descendingComparator(a, b, orderBy)
           : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: Doc<PhotoDoc>[], comparator: (a: Doc<PhotoDoc>, b: Doc<PhotoDoc>) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [Doc<PhotoDoc>, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const usePhotoTableStyles = makeStyles(() =>
                                                  createStyles({
                                                                   table: {
                                                                       minWidth: 750,
                                                                   },
                                                                   visuallyHidden: {
                                                                       border: 0,
                                                                       clip: 'rect(0 0 0 0)',
                                                                       height: 1,
                                                                       margin: -1,
                                                                       overflow: 'hidden',
                                                                       padding: 0,
                                                                       position: 'absolute',
                                                                       top: 20,
                                                                       width: 1,
                                                                   },
                                                               }),
);

interface PhotosTableProps {
    rows: Doc<PhotoDoc>[];
    updatePhoto: (id: string, data: Partial<PhotoDoc>) => Promise<void>;
}

export default function PhotosTable({rows, updatePhoto}: PhotosTableProps) {
    const classes = usePhotoTableStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof PhotoDoc>('path');
    const [selected, setSelected] = React.useState<string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    
    const handleRequestSort = (property: keyof PhotoDoc) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.path);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    
    const handleClick = (name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];
        
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        
        setSelected(newSelected);
    };
    
    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    
    return (
        <>
            <PhotosTableToolbar numSelected={selected.length}/>
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                    aria-label="enhanced table"
                >
                    <PhotosTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                    />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) =>
                                     <PhotoTableRow
                                         photo={row}
                                         key={row.path}
                                         index={index}
                                         isSelected={isSelected}
                                         handleClick={handleClick}
                                         updatePhoto={updatePhoto}/>)}
                        {emptyRows > 0 && (
                            <TableRow style={{height: 53 * emptyRows}}>
                                <TableCell colSpan={6}/>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={(_, newPage) =>
                    handleChangePage(newPage)}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </>
    );
}
