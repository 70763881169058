import {Link as UILink, LinkProps} from "@material-ui/core";
import React, {FC} from 'react';
import {Link as RouterLink, LinkProps as RouterLinkProps} from "react-router-dom"


const FancyLink: FC<LinkProps & RouterLinkProps> = ({children, ...rest}) => {
    return (
        <UILink component={RouterLink} {...rest}>
            {children}
        </UILink>
    );
};

export default FancyLink;
