import {Avatar, CircularProgress, IconButton} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import React from "react";
import {Link as RouterLink} from "react-router-dom";

interface LoadingAvatarImageProps {
    href?: string | null;
    src?: string | null;
    
}

const LoadingAvatarImage = ({href, src}: LoadingAvatarImageProps) => {
    let userImage = <CircularProgress/>;
    const style: { [name: string]: string } = {
        "width": "64px",
        "height": "64px",
    };
    
    if (src) {
        if (href) {
            style["cursor"] = "pointer";
            userImage = <Avatar
                component={RouterLink}
                src={src}
                to={href}
                style={style}
            />;
        } else {
            userImage = <Avatar
                src={src}
                style={style}
            />;
        }
    } else {
        if (href) {
            style["cursor"] = "pointer";
            userImage = (
                <IconButton href={href}>
                    <AccountCircleIcon style={style} fontSize={"large"}/>
                </IconButton>
            );
        } else {
            userImage = <AccountCircleIcon style={style} fontSize={"large"}/>;
        }
    }
    
    return userImage;
}

export default LoadingAvatarImage;
