import {Button, Checkbox, Chip, Switch, TableCell, TableRow, Tooltip, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {InfoOutlined} from "@material-ui/icons";
import EditIcon from '@material-ui/icons/Edit';
import {Skeleton} from "@material-ui/lab";
import moment from "moment/moment";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {PhotoDoc} from "../../../domain/PhotoDoc";
import {Doc} from "../../../misc/Doc";
import {useUrlForPhoto} from "../../../misc/hooks";

const useStyles = makeStyles((theme) => ({
    photoCell: {
        maxWidth: 230,
        minWidth: 230,
        width: 230
    },
    tagsCell: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.3),
        },
    }
}));

interface PhotoTableRowProps {
    index: number,
    photo: Doc<PhotoDoc>,
    handleClick: (name: string) => void,
    isSelected: (name: string) => boolean,
    updatePhoto: (id: string, data: Partial<PhotoDoc>) => Promise<void>,
}

export default function PhotoTableRow({index, photo, handleClick, isSelected, updatePhoto}: PhotoTableRowProps) {
    const classes = useStyles();
    const isItemSelected = isSelected(photo.path);
    const labelId = `enhanced-table-checkbox-${index}`;
    const imageUrl = useUrlForPhoto(photo);
    const [visible, setVisible] = useState(photo.visible);
    const [visibleUpdating, setVisibleUpdating] = useState(false);
    const navigate = useNavigate();
    
    async function togglePublished() {
        setVisibleUpdating(true);
        await updatePhoto(photo.id, {visible: !visible});
        setVisible(!visible);
        setVisibleUpdating(false);
    }
    
    const tags: Array<string> = [];
    if (photo.custom_tags) {
        tags.push(...photo.custom_tags);
    }
    if (photo.visionResults?.labelAnnotations) {
        tags.push(...photo.visionResults.labelAnnotations.filter(a => a.applied && a.description)
                          .map(a => a.description) as string[]);
    }
    
    
    return (
        <>
            <TableRow
                hover
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={photo.path}
                selected={isItemSelected}
            >
                <TableCell padding="checkbox" role="checkbox">
                    <Checkbox
                        onClick={() => handleClick(photo.path)}
                        checked={isItemSelected}
                        inputProps={{'aria-labelledby': labelId}}
                    />
                </TableCell>
                <TableCell className={classes.photoCell} padding={"default"}>
                    {imageUrl ? <img src={imageUrl} alt={photo.title}
                                     width={210}/> : <Skeleton variant="rect" width={210} height={118}/>}
                </TableCell>
                <TableCell component="th" id={labelId} scope="row">
                    {photo.path}
                </TableCell>
                <TableCell>
                    <Typography variant="h5">{photo.title}</Typography>
                </TableCell>
                <TableCell>
                    <Typography>
                        {moment.utc(photo.client_modified).fromNow()}&nbsp;
                        <Tooltip title={moment.utc(photo.client_modified).format("dddd, MMMM Do YYYY, hh:mm:ss")}>
                            <InfoOutlined fontSize={"small"} alignmentBaseline={"baseline"}/>
                        </Tooltip>
                    </Typography>
                </TableCell>
                <TableCell>
                    <Switch checked={visible} onChange={togglePublished} disabled={visibleUpdating}/>
                </TableCell>
                <TableCell>
                    <div className={classes.tagsCell}>
                        {tags.map(tag => <Chip color="primary" size="small" label={tag}/>)}
                    </div>
                </TableCell>
                <TableCell>
                    <Button onClick={() => navigate(`/admin/photos/${photo.id}/edit`)} startIcon={<EditIcon/>}/>
                </TableCell>
            </TableRow>
        </>
);
}
