import {useEffect, useState} from "react";
import {useFirebase} from "../contexts/Firebase";
import {PhotoDoc} from "../domain/PhotoDoc";

export function useUrlForPhoto(photo: PhotoDoc | undefined): string | undefined {
    const [imageUrl, setImageUrl] = useState<string | undefined>()
    const firebase = useFirebase();
    useEffect(() => {
        async function getUrl() {
            if (photo?.sizes?.small?.path) {
                const a = await firebase.storage().ref(photo.sizes.small.path).getDownloadURL();
                setImageUrl(a);
            } else if (photo?.sizes?.medium?.path) {
                const a = await firebase.storage().ref(photo.sizes.medium.path).getDownloadURL();
                setImageUrl(a);
            } else if (photo?.sizes?.large?.path) {
                const a = await firebase.storage().ref(photo.sizes.large.path).getDownloadURL();
                setImageUrl(a);
            } else if (photo?.sizes?.original?.path) {
                const a = await firebase.storage().ref(photo.sizes.original.path).getDownloadURL();
                setImageUrl(a);
            }
        }
        
        getUrl();
    });
    
    return imageUrl;
}
