import {colors, createMuiTheme} from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
    palette: {
        background: {
            default: colors.common.white,
            paper: colors.common.white
        },
        primary: {
            main: colors.indigo[500],
        },
        secondary: {
            main: colors.indigo[500],
        },
        text: {
            primary: colors.blueGrey[900],
            secondary: colors.blueGrey[600],
        }
    },
    shadows,
    typography
});

export default theme;
