import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import assert from "assert";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useFirebase} from "../../contexts/Firebase";
import {GetTokenRequest, GetTokenResponse} from "../../http/reqres";
import FancyLink from "../../misc/FancyLink";
import Loading from "../../misc/Loading";
import {callPostApi} from "../../utils";

export default function DropboxCallbackView() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState<string | undefined>(undefined);
    const firebase = useFirebase();
    useEffect(() => {
        async function extractTokenAndSend(): Promise<null> {
            const url = new URL(window.location.href);
            const body: GetTokenRequest = {};
            // @ts-ignore
            for (let key of url.searchParams.keys()) {
                if (url.searchParams.getAll(key).length > 1) {
                    body[key] = url.searchParams.getAll(key);
                } else {
                    body[key] = url.searchParams.get(key);
                }
            }
            window.history.replaceState && window.history.replaceState(
                null, '', window.location.pathname +
                window.location.search
                      .replace(/[?&]code=[^&]+/, '')
                      .replace(/^&/, '?') +
                window.location.hash
            );
            setLoading(true);
            try {
                const response = await callPostApi<GetTokenRequest, GetTokenResponse>("/dropbox/get-token", body);
                await firebase.auth().setPersistence('local');
                assert(response.token);
                await firebase.auth().signInWithCustomToken(response.token);
                navigate("/admin/dashboard");
            } catch (e) {
                setLoading(false);
                setError("There was an error processing your request. Please try again.");
            }
            return null;
        }

        extractTokenAndSend();
    }, [firebase, navigate]);

    return (
        <>
            {loading && <Loading/>}
            {error &&
            <>
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    There was an error during the login process. Please sign-in again.
                </Alert>
                <FancyLink to={"/auth/login"}>Sign-In</FancyLink>
            </>
            }
        </>
    );
}
