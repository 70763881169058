import {ThemeProvider} from '@material-ui/core';
import React, {FC} from 'react';
// import {FirestoreProvider} from "react-firestore";
import ReactGA from 'react-ga';
import {useLocation, useRoutes} from 'react-router-dom';
import {FirebaseProvider} from "../contexts/Firebase";
import ErrorBoundary from '../misc/ErrorBoundary'
import GlobalStyles from "../misc/GlobalStyles";
import routes from "../routes";
import theme from "../theme";

const LocationListener: FC = ({children}) => {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  React.useEffect(() => {
    const page = location.pathname + location.search;
    ReactGA.set({page})
    ReactGA.pageview(page)
  }, [location]);
  return <>{children}</>;
};

export default function App () {
  const routing = useRoutes(routes);

  return (
      <FirebaseProvider>
        {/*<FirestoreProvider firebase={useFirebase()}>*/}
          <ThemeProvider theme={theme}>
            <GlobalStyles/>
            <LocationListener>
              <ErrorBoundary>
                {routing}
              </ErrorBoundary>
            </LocationListener>
          </ThemeProvider>
        {/*</FirestoreProvider>*/}
      </FirebaseProvider>
  );
}
