import React from 'react';
import {Helmet} from 'react-helmet';

type PageProps = {
    title?: string,
    [x: string]: any;
}

const Page = React.forwardRef<HTMLDivElement, PageProps>(
    (
        {
            children,
            title,
            ...rest
        },
        ref
    ) => {
        return (
            <div {...rest} ref={ref}>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                {children}
            </div>
        )
    }
);

export default Page;
