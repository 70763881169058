import React from "react";
import Page from "../../misc/Page";

const DashboardView = () => {
    return (
        <Page title={"BobisPix Dashboard"}>
            <h1>Dashboard</h1>
        </Page>
    )
};

export default DashboardView;
