import {
    Button,
    Card,
    CardMedia,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    TextField,
    Tooltip,
    Typography
} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ArrowBack, Description, InfoOutlined} from "@material-ui/icons";
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import PhotoSizeSelectLargeIcon from '@material-ui/icons/PhotoSizeSelectLarge';
import {Alert} from "@material-ui/lab";
import ChipInput from "material-ui-chip-input";
import {Calendar, ContentSave} from "mdi-material-ui";
import moment from "moment";
import React, {FC, useState} from "react";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {useNavigate, useParams} from "react-router-dom";
import {useFirebase, useUserStatus} from "../../../../contexts/Firebase";
import {PhotoDoc} from "../../../../domain/PhotoDoc";
import {useUrlForPhoto} from "../../../../misc/hooks";
import Loading from "../../../../misc/Loading";
import Page from "../../../../misc/Page";
import {getReadableFileSizeString} from "../../../../utils";
import {SuggestedTag} from "../SuggestedTag";

const useStyles = makeStyles((theme: Theme) =>
                                 createStyles({
                                                  root: {
                                                      '& .MuiTextField-root': {
                                                          margin: theme.spacing(1),
                                                          width: '25ch',
                                                      },
                                                  },
                                                  image: {
                                                      maxWidth: '345px',
                                                      margin: theme.spacing(3),
                                                  },
                                                  save: {
                                                      backgroundColor: theme.palette.success.light,
                                                      color: theme.palette.success.contrastText,
                                                  }
                                              }),
);

const PhotoEdit: FC = () => {
    const firebase = useFirebase();
    const {user} = useUserStatus();
    const {photoId} = useParams();
    const photoRef = firebase.firestore().doc(`/users/${user?.uid}/photos/${photoId}`);
    const [photo, loading, fbError] = useDocumentData<PhotoDoc>(photoRef);
    const imageUrl = useUrlForPhoto(photo);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState("");
    const classes = useStyles();
    const navigate = useNavigate();
    
    if (fbError) {
        console.error(error);
        return <Typography variant={"h3"}>Error!</Typography>
    }
    
    if (loading) {
        return <Loading/>
    }
    
    if (!photo) {
        return <Typography variant={"h3"}>Couldn't find Photo {photoId}!</Typography>
    }
    
    async function handleSave(): Promise<void> {
        if (!photo) {
            console.error("Can't save something that was not defined!");
            return;
        }
        setSaving(true);
        try {
            await photoRef.update(photo);
        } catch (e) {
            console.error(e);
            setError("Error while saving entity. Try again, or reload.");
            setSaving(false);
        }
        if (!error) {
            navigate("/admin/photos");
        }
    }
    
    return (
        <Page title={"Edit Photo"}>
            <Grid container spacing={3}>
                <Grid item container xs={12} style={{marginTop: "10px"}}>
                    <Grid item container xs={1} alignContent={"flex-end"}>
                        <Button variant={"text"} startIcon={<ArrowBack/>} href={"/admin/photos"}>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={11}>
                        {error.trim().length > 0 ? <Alert onClose={() => setError("")}
                                                          severity="error">{error}</Alert> : ""}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} container alignItems={"center"} alignContent={"center"}
                      justify={"center"}>
                    <Grid item>
                        <Card className={classes.image}>
                            <CardMedia
                                component="img"
                                alt={photo.title}
                                image={imageUrl}
                                    title={photo.title}
                                />
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <Calendar/>
                                </ListItemIcon>
                                <ListItemText id="time-taken"
                                              primary={moment.utc(photo.client_modified).fromNow()}/>
                                <ListItemSecondaryAction>
                                    <Tooltip title={moment.utc(photo.client_modified)
                                                          .format("dddd, MMMM Do YYYY, hh:mm:ss")}>
                                        <InfoOutlined fontSize={"small"} alignmentBaseline={"baseline"}/>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <PhotoSizeSelectLargeIcon/>
                                </ListItemIcon>
                                <ListItemText id="size"
                                              primary={getReadableFileSizeString(photo.size)}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <AspectRatioIcon/>
                                </ListItemIcon>
                                <ListItemText id="dimensions"
                                              primary={`${photo.blobMetadata?.customMetadata?.dimensions?.width} X ${photo.blobMetadata?.customMetadata?.dimensions?.height}`}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <Description/>
                                </ListItemIcon>
                                <ListItemText id="dimensions"
                                              primary={`${photo.blobMetadata?.contentType}`}/>
                            </ListItem>
                        </List>
                    
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id={"title"} name={"title"} label={"Title"} fullWidth autoComplete={"title"}
                                   value={photo.title}
                                   onChange={(event) => photo.title = event.target.value}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Description"
                            onChange={(event) => photo.description = event.target.value}
                            multiline
                            fullWidth
                            rows={4}
                            defaultValue={photo.description}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h5" gutterBottom>
                            Suggested Tags
                        </Typography>
                        {photo.visionResults?.labelAnnotations?.sort(tag => tag.score || 0)
                              .map(tag => <SuggestedTag key={tag.description} tag={tag}/>)}
                    </Grid>
                <Grid item xs={12} sm={6}>
                    <ChipInput
                        onChange={tags => photo.custom_tags = tags.map(t => t.toString())}
                        defaultValue={photo.custom_tags}
                        fullWidth
                        label='Custom Tags'
                        placeholder='Type and press enter to add tags'
                    />
                </Grid>
                <Grid container item xs={12} justify={"flex-end"} spacing={10}>
                    <Grid item>
                        <Button variant={"contained"}
                                className={classes.save}
                                startIcon={<ContentSave/>}
                                disabled={saving}
                                onClick={handleSave}>Save</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Page>
    );
}

export default PhotoEdit;
