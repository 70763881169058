import {Button} from "@material-ui/core";
import {FC} from "react";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {PhotoDoc} from "../../../../../lib/shared/domain/PhotoDoc";
import {useFirebase, useUserStatus} from "../../../contexts/Firebase";
import {Doc} from "../../../misc/Doc";
import ErrorMessage from "../../../misc/ErrorMessage";
import Loading from "../../../misc/Loading";
import Page from "../../../misc/Page";
import {callPostApi} from "../../../utils";
import PhotosTable from "./PhotosTable";

const PhotosView: FC = () => {
    const firebase = useFirebase();
    const {user, account} = useUserStatus();
    const photoCollection = firebase.firestore().collection(`/users/${user?.uid}/photos`);
    const [values, loading, error] = useCollectionData<Doc<PhotoDoc>>(
        photoCollection, {idField: "id"}
    );
    if (!user) {
        return <></>;
    }
    
    async function updatePhoto(id: string, data: Partial<PhotoDoc>): Promise<void> {
        await photoCollection.doc(`${id}`).set(data, {merge: true});
        console.log(`Photo ${id} updated (${JSON.stringify(data)})`);
    }
    
    let content;
    if (error) {
        console.error(error);
        content = <ErrorMessage error={error}/>;
    } else if (loading) {
        content = <Loading/>;
    } else if (!values) {
        content = "No Photos found";
    } else {
        content = (
            <PhotosTable rows={values} updatePhoto={updatePhoto}/>
        );
    }
    
    async function syncPhotos() {
        await callPostApi("/dropbox/sync-photos", {accountId: account?.dropboxAccount.account_id});
    }
    
    
    return (
        <Page title={"All Photos"}>
            <Button variant={"outlined"} onClick={syncPhotos}>Sync Photos</Button>
            {content}
        </Page>
    );
}

export default PhotosView;
