import React, {ErrorInfo, FC} from 'react'
import FancyLink from "./FancyLink";

export type ErrorProps = {
    error?: Error;
    info?: ErrorInfo;
}

const Error: FC<ErrorProps> = ({error, info}) => {
    return (
        <div>
            <h1>Whoops</h1>
            <p>{`Sorry, something went wrong. We're looking into it.`}</p>
            <div style={{fontFamily: 'monospace'}}>{error ? error.message : null}</div>
            <div style={{fontFamily: 'monospace'}}>{info ? info : null}</div>
            <FancyLink to="/">Go to the homepage</FancyLink>
        </div>
    );
}

export default Error
