import * as React from "react";
import {FC, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {LoggedInStatus, useUserStatus} from "../contexts/Firebase";
import Loading from "./Loading";

const PrivateView: FC = ({children}) => {
    const userStatus = useUserStatus();
    const navigate = useNavigate();
    const [loggedInStatus, setLoggedInStatus] = useState(userStatus.status);
    
    useEffect(() => {
        setLoggedInStatus(userStatus.status);
    }, [userStatus]);
    
    if (loggedInStatus === LoggedInStatus.LOGGED_OUT) {
        navigate("/auth/login", {state: {redirectTt: window.location}});
        return <></>;
    } else if (loggedInStatus === LoggedInStatus.LOGGED_IN) {
        return <>{children}</>;
    } else {
        return <Loading/>;
    }
};

export default PrivateView;
