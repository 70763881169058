import {makeStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.background.default,
            // position: 'absolute',
            // top: 0,
            // left: 0,
            // zIndex: 10000,
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            width: '100%'
        },
        circle: {
            height: '50ox',
            width: '50ox',
        },
    }))
;

export default function Loading() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CircularProgress className={classes.circle}/>
        </div>
    );
}
