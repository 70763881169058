import {Box, Button, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {LoggedInStatus, useUserStatus} from "../../contexts/Firebase";
import {GetAuthenticationUrlResponse} from "../../http/reqres";
import DropboxIcon from "../../misc/DropboxIcon";
import Loading from "../../misc/Loading";
import Page from "../../misc/Page";
import {callGetApi} from "../../utils";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const LoginView = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {status} = useUserStatus();
    
    if (status === LoggedInStatus.UNKNOWN) {
        return <Loading/>;
    } else if (status === LoggedInStatus.LOGGED_IN) {
        navigate("/admin/dashboard");
        return null;
    }
    
    async function getUrlAndRedirect() {
        const response = await callGetApi<GetAuthenticationUrlResponse>("/dropbox/get-authentication-url");
        if (response && response.redirectUrl) {
            window.location.href = response.redirectUrl;
            return;
        }
    }
    
    return (
        <Page
            className={classes.root}
            title="Login"
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container maxWidth="sm">

                    <Box mb={3}>
                        <Typography
                            color="textPrimary"
                            variant="h2"
                        >
                            Sign in
                        </Typography>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body2"
                        >
                            Sign in to the BobisPix Admin Dashboard
                        </Typography>
                    </Box>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Button
                            color="primary"
                            fullWidth
                            startIcon={<DropboxIcon viewBox={"0 0 44 44"} width={"22"} height={"22"}/>}
                            onClick={getUrlAndRedirect}
                            size="large"
                            variant="contained"
                        >
                            Login with Dropbox
                        </Button>
                    </Grid>
                </Container>
            </Box>
        </Page>
    );
};

export default LoginView;
