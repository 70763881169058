// catch errors in our app and show the error screen instead of just breaking
// https://reactjs.org/docs/error-boundaries.html

import React, {ErrorInfo} from 'react'

import Error, {ErrorProps} from './Error'

class ErrorBoundary extends React.Component<{}, ErrorProps> {
  
  componentDidCatch(error: Error, info: ErrorInfo): void {
    console.error(error, info);
    this.setState({error, info});
  }
  
  render() {
    if (this.state.error) {
      return <Error error={this.state.error} info={this.state.info}/>
    }
    return this.props.children
  }
  
  state: ErrorProps = {
    error: undefined,
    info: undefined,
  }
}

export default ErrorBoundary
