import React from "react";
import {AlertTitle} from "@material-ui/lab";
import {Alert} from "@material-ui/lab";

export type ErrorMessageProps = {
    error: string | Error
};


export default function ErrorMessage({error}: ErrorMessageProps) {
    return (
        <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {typeof error === "string" ? error : error.message} — <strong>Please reload and try again</strong>
        </Alert>
    );
}
